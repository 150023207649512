import React from 'react';


import ServicesGrid from './servicesgrid'
import ServicesHeader from './servicesheader'
import './services.css'


const ServicesPage = () => (
        <>
        <ServicesHeader />
        <ServicesGrid />
      
      </>
    );

export default ServicesPage